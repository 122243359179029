import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["radio", "field"];

  connect() {
    this.updateFields();
  }

  updateFields() {
    const hide = !["movements", "invoice_lines"].includes(this.exportType);

    this.fieldTarget.classList.toggle("c-form--fieldset-hidden", hide);
  }

  get exportType() {
    return this.radioTargets.find((radio) => radio.checked).value;
  }
}
